
// import $app from '@/plugins/modules';
import { IManipulationType } from '@/modules/clinic/types';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ICuringPlan } from '../types';

@Component
export default class ToothNewManipulationsDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly plan: number | undefined;

  @Prop({ default: () => [] })
  readonly items: Array<IManipulationType> | undefined;

  @Prop({ default: () => [] })
  readonly plans: Array<ICuringPlan> | undefined;

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get currentPlan() {
    return this.plan || null;
  }

  set currentPlan(value: number | null) {
    this.$emit('select', value);
  }

  newPlan() {
    this.$emit('create')
  }

  process() {
    this.$emit('process')
  }

  remove(index: number) {
    this.$emit('remove', index)
  }
}
